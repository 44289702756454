import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import StorygramGUI from './components/StorygramWrapper';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "layout"
    }}>{`Layout`}</h1>
    <p>{`It is possible to set a range of visual options of the Storygram.`}</p>
    <h2 {...{
      "id": "continuous"
    }}>{`Continuous`}</h2>
    <p>{`Whether all actors should be visible from the beginning to the end of the Storygram.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`continuous: boolean;
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: false
`}</code></pre>
    <Playground __position={0} __code={'<StorygramGUI\n  data={[\n    { event: 0, actors: [\'a\', \'b\', \'c\'] },\n    { event: 2, actors: [\'e\', \'b\'] },\n    { event: 10, actors: [\'d\', \'b\', \'e\'] },\n    { event: 12, actors: [\'d\', \'a\'] },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    continuous: true,\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 0,
        actors: ['a', 'b', 'c']
      }, {
        event: 2,
        actors: ['e', 'b']
      }, {
        event: 10,
        actors: ['d', 'b', 'e']
      }, {
        event: 12,
        actors: ['d', 'a']
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        continuous: true
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "compact"
    }}>{`Compact`}</h2>
    <p>{`Whether the Storygram is compacted around the central x-axis or not. This is useful in case the groups are of a similar size over time, like governments, soccer teams etc.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`compact: boolean;
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: false
`}</code></pre>
    <Playground __position={1} __code={'<StorygramGUI\n  data={[\n    { event: 0, actors: [\'a\', \'b\', \'c\'] },\n    { event: 1, actors: [\'a\', \'c\', \'d\'] },\n    { event: 2, actors: [\'c\', \'d\', \'e\'] },\n    { event: 3, actors: [\'e\', \'f\', \'g\'] },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    compact: true,\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 0,
        actors: ['a', 'b', 'c']
      }, {
        event: 1,
        actors: ['a', 'c', 'd']
      }, {
        event: 2,
        actors: ['c', 'd', 'e']
      }, {
        event: 3,
        actors: ['e', 'f', 'g']
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        compact: true
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "actor-color"
    }}>{`Actor color`}</h2>
    <p>{`Callback that returns a string or a number indicating a color category. Note: it is not possible to assign directly a color to an actor, it will get a color of the selected
`}<a parentName="p" {...{
        "href": "/layout#color-scheme"
      }}>{`color scheme`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`actorColor: (event: Event, actor: Actor) => string | number;
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: (event, actor) => actor.actorID
`}</code></pre>
    <Playground __position={2} __code={'<StorygramGUI\n  data={[\n    { actor: \'a\', end: 1, team: \'one\' },\n    { actor: \'b\', end: 2, team: \'two\' },\n    { actor: \'c\', start: 0, team: \'two\' },\n    { actor: \'d\', start: 2, end: 3, team: \'one\' },\n    { actor: \'e\', start: 1, end: 2, team: \'one\' },\n  ]}\n  config={{\n    dataFormat: \'ranges\',\n    actorField: \'actor\',\n    startField: \'start\',\n    endField: \'end\',\n    actorColor: (event, actor) => actor.data.team,\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        actor: 'a',
        end: 1,
        team: 'one'
      }, {
        actor: 'b',
        end: 2,
        team: 'two'
      }, {
        actor: 'c',
        start: 0,
        team: 'two'
      }, {
        actor: 'd',
        start: 2,
        end: 3,
        team: 'one'
      }, {
        actor: 'e',
        start: 1,
        end: 2,
        team: 'one'
      }]} config={{
        dataFormat: 'ranges',
        actorField: 'actor',
        startField: 'start',
        endField: 'end',
        actorColor: (event, actor) => actor.data.team
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "highlight"
    }}>{`Highlight`}</h2>
    <p>{`Array containing the actors that are highlighted.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`highlight: string[];
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: []
`}</code></pre>
    <Playground __position={3} __code={'<StorygramGUI\n  data={[\n    { event: 0, actors: [\'a\', \'b\', \'c\'] },\n    { event: 1, actors: [\'a\', \'c\', \'d\'] },\n    { event: 2, actors: [\'c\', \'d\', \'e\'] },\n    { event: 3, actors: [\'e\', \'f\', \'g\'] },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    highlight: [\'c\', \'d\'],\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 0,
        actors: ['a', 'b', 'c']
      }, {
        event: 1,
        actors: ['a', 'c', 'd']
      }, {
        event: 2,
        actors: ['c', 'd', 'e']
      }, {
        event: 3,
        actors: ['e', 'f', 'g']
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        highlight: ['c', 'd']
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "event-description"
    }}>{`Event description`}</h2>
    <p>{`  Callback that returns a string describing the selected event. Note that based on the length of the descriptions you might have to adjust also the `}<a parentName="p" {...{
        "href": "/layout#topbottomleftright-margin"
      }}>{`right margin`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`  eventDescription: (arg: Event) => string;
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: event => String(event.eventValue)
`}</code></pre>
    <Playground __position={4} __code={'<StorygramGUI\n  data={[\n    { event: 0, actors: [\'a\', \'b\', \'c\'], description: \'First event\' },\n    { event: 1, actors: [\'d\', \'b\', \'e\'], description: \'Parallel event\' },\n    { event: 2, actors: [\'d\', \'a\'], description: \'Second event\' },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    eventDescription: event =>\n      event.data.event + \'. \' + event.data.description,\n    marginRight: 150,\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 0,
        actors: ['a', 'b', 'c'],
        description: 'First event'
      }, {
        event: 1,
        actors: ['d', 'b', 'e'],
        description: 'Parallel event'
      }, {
        event: 2,
        actors: ['d', 'a'],
        description: 'Second event'
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        eventDescription: event => event.data.event + '. ' + event.data.description,
        marginRight: 150
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "url"
    }}>{`Url`}</h2>
    <p>{`Every actor in every event can have a custom URL. This is done by creating a callback that has as parameters the current actor and event, and returns a URL string.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`url: (event: Event, actor: Actor) => string;
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: (event, actor) => 'https://www.google.ch/search?q=' + String(event.eventValue) + ' ' + actor.actorID
`}</code></pre>
    <Playground __position={5} __code={'<StorygramGUI\n  data={[\n    {\n      event: 2002,\n      actors: [\'USA\', \'Germany\', \'Japan\'],\n      description: \'Olympic Games\',\n    },\n    {\n      event: 2006,\n      actors: [\'Greece\', \'Senegal\', \'Nicaragua\'],\n      description: \'FIFA Cup\',\n    },\n    {\n      event: 2004,\n      actors: [\'Greece\', \'Germany\'],\n      description: \'UEFA Cup\',\n    },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    url: (event, actor) =>\n      \'https://www.google.ch/search?q=\' +\n      String(event.eventValue) +\n      \' \' +\n      event.data.description +\n      \' \' +\n      actor.actorID,\n    marginRight: 100,\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 2002,
        actors: ['USA', 'Germany', 'Japan'],
        description: 'Olympic Games'
      }, {
        event: 2006,
        actors: ['Greece', 'Senegal', 'Nicaragua'],
        description: 'FIFA Cup'
      }, {
        event: 2004,
        actors: ['Greece', 'Germany'],
        description: 'UEFA Cup'
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        url: (event, actor) => 'https://www.google.ch/search?q=' + String(event.eventValue) + ' ' + event.data.description + ' ' + actor.actorID,
        marginRight: 100
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "event-url"
    }}>{`Event Url`}</h2>
    <p>{`Every event can have a custom URL. This is done by creating a callback that has as parameter the current event, and returns a URL string. The event URL is accessed by clicking on the event description on the top.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`eventUrl: (event: Event) => string;
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: (event) => 'https://www.google.ch/search?q=' + String(event.eventValue)
`}</code></pre>
    <Playground __position={6} __code={'<StorygramGUI\n  data={[\n    {\n      event: 2002,\n      actors: [\'USA\', \'Germany\', \'Japan\'],\n      description: \'Olympic Games\',\n    },\n    {\n      event: 2006,\n      actors: [\'Greece\', \'Senegal\', \'Nicaragua\'],\n      description: \'FIFA Cup\',\n    },\n    {\n      event: 2004,\n      actors: [\'Greece\', \'Germany\'],\n      description: \'UEFA Cup\',\n    },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    eventUrl: event =>\n      \'https://www.google.ch/search?q=\' +\n      event.data.description +\n      String(event.eventValue),\n    marginRight: 100,\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 2002,
        actors: ['USA', 'Germany', 'Japan'],
        description: 'Olympic Games'
      }, {
        event: 2006,
        actors: ['Greece', 'Senegal', 'Nicaragua'],
        description: 'FIFA Cup'
      }, {
        event: 2004,
        actors: ['Greece', 'Germany'],
        description: 'UEFA Cup'
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        eventUrl: event => 'https://www.google.ch/search?q=' + event.data.description + String(event.eventValue),
        marginRight: 100
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "event-value-scaling"
    }}>{`Event value scaling`}</h2>
    <p>{`Event value scaling factor (0.0 doesn't scale, 1.0 completely scales).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`eventValueScaling: number;
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: 0.9
`}</code></pre>
    <Playground __position={7} __code={'<StorygramGUI\n  data={[\n    { event: 0, actors: [\'a\', \'b\', \'c\'] },\n    { event: 2, actors: [\'e\', \'b\'] },\n    { event: 10, actors: [\'d\', \'b\', \'e\'] },\n    { event: 12, actors: [\'d\', \'a\'] },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    eventValueScaling: 0.9,\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 0,
        actors: ['a', 'b', 'c']
      }, {
        event: 2,
        actors: ['e', 'b']
      }, {
        event: 10,
        actors: ['d', 'b', 'e']
      }, {
        event: 12,
        actors: ['d', 'a']
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        eventValueScaling: 0.9
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "actor-padding"
    }}>{`Actor padding`}</h2>
    <p>{`Padding between the actors.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`actorPadding: number;
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: 30
`}</code></pre>
    <Playground __position={8} __code={'<StorygramGUI\n  data={[\n    { event: 0, actors: [\'a\', \'b\', \'c\'] },\n    { event: 1, actors: [\'d\', \'b\', \'e\'] },\n    { event: 2, actors: [\'d\', \'a\'] },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    actorPadding: 20,\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 0,
        actors: ['a', 'b', 'c']
      }, {
        event: 1,
        actors: ['d', 'b', 'e']
      }, {
        event: 2,
        actors: ['d', 'a']
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        actorPadding: 20
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "event-padding"
    }}>{`Event padding`}</h2>
    <p>{`Padding between the events.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`  eventPadding: number;
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: 40
`}</code></pre>
    <Playground __position={9} __code={'<StorygramGUI\n  data={[\n    { event: 0, actors: [\'a\', \'b\', \'c\'] },\n    { event: 1, actors: [\'d\', \'b\', \'e\'] },\n    { event: 2, actors: [\'d\', \'a\'] },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    eventPadding: 20,\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 0,
        actors: ['a', 'b', 'c']
      }, {
        event: 1,
        actors: ['d', 'b', 'e']
      }, {
        event: 2,
        actors: ['d', 'a']
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        eventPadding: 20
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "line-size"
    }}>{`Line size`}</h2>
    <p>{`Line size of the actors and groups.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`  lineSize: number;
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: 9
`}</code></pre>
    <Playground __position={10} __code={'<StorygramGUI\n  data={[\n    { event: 0, actors: [\'a\', \'b\', \'c\'] },\n    { event: 1, actors: [\'d\', \'b\', \'e\'] },\n    { event: 2, actors: [\'d\', \'a\'] },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    lineSize: 20,\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 0,
        actors: ['a', 'b', 'c']
      }, {
        event: 1,
        actors: ['d', 'b', 'e']
      }, {
        event: 2,
        actors: ['d', 'a']
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        lineSize: 20
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "color-scheme"
    }}>{`Color scheme`}</h2>
    <p>{`  Name of the used `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-scale-chromatic"
      }}>{`d3 color scheme`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`  colorScheme:
  | 'schemeCategory10' | 'schemeAccent' | 'schemeDark2' | 'schemePaired' | 'schemePastel1' | 'schemePastel2' | 'schemeSet1' | 'schemeSet2' | 'schemeSet3';
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: 'schemeAccent'
`}</code></pre>
    <Playground __position={11} __code={'<StorygramGUI\n  data={[\n    { event: 0, actors: [\'a\', \'b\', \'c\'] },\n    { event: 1, actors: [\'d\', \'b\', \'e\'] },\n    { event: 2, actors: [\'d\', \'a\'] },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    colorScheme: \'schemeSet1\',\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 0,
        actors: ['a', 'b', 'c']
      }, {
        event: 1,
        actors: ['d', 'b', 'e']
      }, {
        event: 2,
        actors: ['d', 'a']
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        colorScheme: 'schemeSet1'
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "hidden-actors-tooltip-title"
    }}>{`Hidden Actors Tooltip Title`}</h2>
    <p>{`  Title of the tooltip displaying the hidden actors.`}</p>
    <Playground __position={12} __code={'<StorygramGUI\n  data={[\n    { event: 0, actors: [\'a\', \'b\', \'c\'] },\n    { event: 1, actors: [\'d\', \'b\', \'e\'] },\n    { event: 2, actors: [\'d\', \'a\'] },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    filterGroupAmt: [2, undefined],\n    hiddenActorsTooltipTitle: \'Hidden letters\',\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 0,
        actors: ['a', 'b', 'c']
      }, {
        event: 1,
        actors: ['d', 'b', 'e']
      }, {
        event: 2,
        actors: ['d', 'a']
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        filterGroupAmt: [2, undefined],
        hiddenActorsTooltipTitle: 'Hidden letters'
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "url-opens-new-tab"
    }}>{`Url opens new tab`}</h2>
    <p>{`Boolean that indicates if a new tab should be opened by clicking on the Storygram.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`urlOpensNewTab: boolean
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: true
`}</code></pre>
    <Playground __position={13} __code={'<StorygramGUI\n  data={[\n    {\n      event: 2002,\n      actors: [\'USA\', \'Germany\', \'Japan\'],\n      description: \'Olympic Games\',\n    },\n    {\n      event: 2006,\n      actors: [\'Greece\', \'Senegal\', \'Nicaragua\'],\n      description: \'FIFA Cup\',\n    },\n    {\n      event: 2004,\n      actors: [\'Greece\', \'Germany\'],\n      description: \'UEFA Cup\',\n    },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    url: (event, actor) =>\n      \'https://www.google.ch/search?q=\' +\n      String(event.eventValue) +\n      \' \' +\n      event.data.description +\n      \' \' +\n      actor.actorID,\n    marginRight: 100,\n    urlOpensNewTab: true,\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 2002,
        actors: ['USA', 'Germany', 'Japan'],
        description: 'Olympic Games'
      }, {
        event: 2006,
        actors: ['Greece', 'Senegal', 'Nicaragua'],
        description: 'FIFA Cup'
      }, {
        event: 2004,
        actors: ['Greece', 'Germany'],
        description: 'UEFA Cup'
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        url: (event, actor) => 'https://www.google.ch/search?q=' + String(event.eventValue) + ' ' + event.data.description + ' ' + actor.actorID,
        marginRight: 100,
        urlOpensNewTab: true
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "topbottomleftright-margin"
    }}>{`Top/Bottom/Left/Right Margin`}</h2>
    <p>{`Margins around the storygram. In the example below the Bottom and the Right margin is expanded to show the entire text.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`marginTop: number
marginBottom: number
marginLeft: number
marginRight: number
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`Default value: 50
`}</code></pre>
    <Playground __position={14} __code={'<StorygramGUI\n  data={[\n    {\n      event: \'1/4/2019\',\n      actors: [\'a\', \'b\', \'c\'],\n      description: \'First event\',\n    },\n    {\n      event: \'2/4/2019\',\n      actors: [\'d\', \'b\', \'e\'],\n      description: \'Parallel event\',\n    },\n    { event: \'3/4/2019\', actors: [\'d\', \'a\'], description: \'Second event\' },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    marginBottom: 70,\n    marginRight: 80,\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: '1/4/2019',
        actors: ['a', 'b', 'c'],
        description: 'First event'
      }, {
        event: '2/4/2019',
        actors: ['d', 'b', 'e'],
        description: 'Parallel event'
      }, {
        event: '3/4/2019',
        actors: ['d', 'a'],
        description: 'Second event'
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        marginBottom: 70,
        marginRight: 80
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      